'use strict';

angular.module('informaApp')
    .directive('infGanttChartLegend',
        function () {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-gantt-chart-profile/inf-gantt-chart-legend/template.ptl.html',
                scope: {
                    active: '=',
                    filter: '=',
                    hiddenPhases: '='
                },
                applyItemStyle: function (phaseClass, element, checked, active = true) {
                    var g = element.find('g.' + phaseClass);

                    g.find('text.phase-name, text.status-name').attr('text-decoration', checked || !active ? null : 'line-through');
                },
                applyFilter: function (element, filter, active) {
                    this.applyItemStyle('phase-1', element, filter.phase1, active);
                    this.applyItemStyle('phase-2', element, filter.phase2, active);
                    this.applyItemStyle('phase-3', element, filter.phase3, active);
                    this.applyItemStyle('ndabla', element, filter.ndabla, active);
                    this.applyItemStyle('no-data', element, filter.noData, active);
                    this.applyItemStyle('succeeded', element, filter.succeeded);
                    this.applyItemStyle('in-progress', element, filter.inProgress);
                    this.applyItemStyle('failed', element, filter.failed);
                },
                link: function (scope, element, attr) {
                    scope.$watch('filter', (newValue) => {
                        if (newValue) {
                            this.applyFilter(element, newValue, scope.active);
                        }
                    }, true);

                    scope.$watch('active', (newValue) => {
                        if (scope.filter){
                            this.applyFilter(element, scope.filter, newValue);
                        }
                    }, true);

                    scope.phaseClick = function (phase) {
                        if (!scope.active) {
                            return;
                        }

                        filterClick(phase, [
                            scope.filter.phase1, scope.filter.phase2, scope.filter.phase3,
                            scope.filter.ndabla, scope.filter.noData], scope.hiddenPhases);
                    };

                    scope.statusClick = function (status) {
                        filterClick(status, [scope.filter.succeeded, scope.filter.inProgress, scope.filter.failed]);
                    };

                    function filterClick(item, allItems, hiddenItems){
                        if ((scope.filter[item] && !canHide(allItems, hiddenItems))){
                            return;
                        }

                        scope.filter[item] = !scope.filter[item];
                    }

                    function canHide(allItems, hiddenItems) {
                        const hiddenItemsCount = _.values(hiddenItems).filter(function(x) { return x; }).length;

                        return allItems.filter(function(x) { return x; }).length - hiddenItemsCount > 1;
                    }
                }
            }
        });